export const PhotosCharter = [
  {
    src: "https://pro2-bar-s3-cdn-cf.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/10b4fed123f44447ce8f84fe_rw_1200.jpg?h=46c2790bade5955c5432f134a5c76d26",
    width: 1200,
    height: 800
  },
  {
    src: "https://pro2-bar-s3-cdn-cf5.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/6d948c9ceacd93faf5fe6d5b_rw_1200.jpg?h=380db8c3c237374bf5b1e6666f4cc1c6",
    width: 1200,
    height: 800
  },
  {
    src: "https://pro2-bar-s3-cdn-cf2.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/d7d9b6f65e9240dbf5d1cbcb_rw_1200.jpg?h=578e6713796fd0122142c7b46aefe860",
    width: 800,
    height: 1200
  },
  {
    src: "https://pro2-bar-s3-cdn-cf4.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/55f31e72680dc378de9c11af_rw_1200.jpg?h=f14ad88bb4314f41d75b2e358f38e65f",
    width: 800,
    height: 1200
  },
  {
    src: "https://pro2-bar-s3-cdn-cf5.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/6604f132a72c26e60f24bef6_rw_1200.jpg?h=b87254eb4f09b89517c4a412d734c150",
    width: 800,
    height: 1200
  },
  {
    src: "https://pro2-bar-s3-cdn-cf6.myportfolio.com/9c0c32ffbfb6bec1105e5a0038d455f6/a21849fafcdddc7ee71bd3e6_rw_1200.jpg?h=cb9b9e329a7832a8a46f67e824d800d9",
    width: 800,
    height: 1200
  }
];
