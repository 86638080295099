import { Container, Row, Col } from "react-bootstrap";
import HubspotForm from "react-hubspot-form";
import studio from '../img/studio.jpg';

export default function Contact() {
  document.title = "Mediavelocity Contact";

  return (
    <>
      <Container
        fluid


      >
        <Row>
          <Col
            md={6}
            className="bg-secondary text-white d-flex align-items-center justify-content-start pe-5 largeImageCover"
            style={{ minHeight: "65vh",  backgroundImage: "url(" + studio + ")",
              backgroundPosition: "center" }}
          >
            <h5 className="ls-3 text-uppercase lead" style={{ fontSize: '8vw', lineHeight: '90%' }}>
              you found your solution
            </h5>
          </Col>
          <Col className="p-5">
      <h3>Contact Us</h3>
      <HubspotForm
        portalId="47831684"
        formId="dc95d496-1b16-4724-af08-f73231cff688"
        onSubmit={() => console.log('Form submitted!')}
        onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading form...</div>}
      />
          </Col>
        </Row>
      </Container>


    </>
  );
}
