// import { FaEnvelope, FaPhone, FaYoutube, FaBehance, FaLinkedin, FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const James = () => {
    document.title = "Mediavelocity - James Gerhold"

    return(
        <>
        hi bingo
        </>
    )
}

export default James;