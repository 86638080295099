
import { Container } from "react-bootstrap";

import React, { useEffect } from 'react';


export default function Pay() {

    const loadStripeScript = (callback) => {
        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/buy-button.js";
        script.async = true;
        script.onload = () => callback();
        document.body.appendChild(script);
      };

      const StripeBuyButton = () => {
        useEffect(() => {
          loadStripeScript(() => {
            const stripeBuyButton = document.createElement('stripe-buy-button');
            stripeBuyButton.setAttribute('buy-button-id', 'buy_btn_1OIyiBCyJEWYklbY4wVpWRrB');
            stripeBuyButton.setAttribute('publishable-key', 'pk_live_51OG1zfCyJEWYklbYQh8QqyFscLLi6WolYw0m6YQ4y3pJCkwnZIV5vvSx32E6Cdykm5a4vDWIoqdRAdUwMmupB0aI00XXu8Y6jE');
      
            document.getElementById('stripe-button-container').appendChild(stripeBuyButton);
          });
        }, []);
      
        return <div id="stripe-button-container" />;
      };
      

return (
    <Container className="my-3 d-flex flex-column align-items-center justify-content-center"
style={{minHeight:"30rem"}}>


<div className="App">
      <h1>Payment Page</h1>
      <StripeBuyButton />
    </div>


</Container>
)


}
